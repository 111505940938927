import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import IndexPage from "../views/index/IndexPage.vue"
import MainPage from "../views/index/MainPage.vue"
import LaravelPhpPage from "../views/index/LaravelPhpPage.vue"
import SpringbootJavaPage from "../views/index/SpringbootJavaPage.vue"
import JavascriptVuePage from "../views/index/JavascriptVuePage.vue"
import ResearchTopicPage from "../views/index/ResearchTopicPage.vue"
import SqlPage from "../views/index/SqlPage.vue"
import ContentPage from "../views/index/ContentPage.vue"
import AboutPage from "../views/index/AboutPage.vue"
import ContactPage from "../views/index/ContactPage.vue"
import UploadContent from "../views/adminpages/AddContent.vue"
import TrainingPage from "../views/index/TrainingPage.vue"

Vue.use(VueRouter);
// parse jwt token
// function parseJwt (token) {
//   var base64Url = token.split('.')[1];
//   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
//       return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
//   }

// function beforeRouteEnter (to, from, next) {
//   try{
//   if (localStorage.getItem('wertyuiojhdfghhdrtfyguh')) {
//     const jwtPayload = parseJwt(localStorage.getItem('wertyuiojhdfghhdrtfyguh'));
//     if (jwtPayload.exp < Date.now()/1000) {
//         // token expired
//         //deleteTokenFromLocalStorage();
//         next("/");
//     }
//     next();
//   } else {
//     next("/");
//   }
//   }catch (err) {
//     console.log(err);
//   }
// }

/*
function guardMyroute(to, from, next)
{
    var isAuthenticated= false;
//this is just an example. You will have to find a better or 
// centralised way to handle you localstorage data handling 
    if(localStorage.getItem('wertyuiojhdfghhdrtfyguh'))
      isAuthenticated = true;
    else
      isAuthenticated= false; if(isAuthenticated) 
    {
      next(); // allow to enter route
    } 
    else
    {
      next('/'); // go to '/login';
    }
    //next("/business-card");
}
*/
const routes = [
 
  {
    path: '/upload-content',
    name: 'UploadContent',
    component: UploadContent
  },

  {
    path: '/',
    name: 'IndexPage',
    component: IndexPage,
    props: true,
    meta: {
      secure:false,
    },
    
    children: [
      {
        path: '/',
        name: 'MainPage',
        component: MainPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/codinghub',
        name: 'TrainingPage',
        component: TrainingPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/php-laravel',
        name: 'LaravelPhpPage',
        component: LaravelPhpPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/java-springboot',
        name: 'SpringbootJavaPage',
        component: SpringbootJavaPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/javascript-vue',
        name: 'JavascriptVuePage',
        component: JavascriptVuePage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/sql-query',
        name: 'SqlPage',
        component: SqlPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/research-material',
        name: 'ResearchTopicPage',
        component: ResearchTopicPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/view/:topic',
        name: 'ContentPage',
        component: ContentPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/about-us',
        name: 'AboutPage',
        component: AboutPage,
        meta: {
          secure:true,
        }
      },
      {
        path: '/contact-us',
        name: 'ContactPage',
        component: ContactPage,
        meta: {
          secure:true,
        }
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
